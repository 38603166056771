import Vue from "vue";
import App from "./App.vue";
import axios from 'axios'
// import "../public/static/tour.js";

Vue.prototype.$axios = axios;
axios.defaults.withCredentials=true;
axios.defaults.crossDomain=true;
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
